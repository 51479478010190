.sidebar li.active {
    background-color: #DAE6FF;
    color: #4484FF;
    font-family: 'Inter';
    font-size: 19px;
  }

.sidebar{
    background-color: #FFFFFF;
}



.content {
    background-color: #F9F9F9;
}

.container {
    background-color: #FFFFFF;
    border: 3px solid #0099ff;

    padding: 34px 19px;
    margin: 14px 19px;
    border-radius: 23px;
    width: 25%;
    height: 25%;
}

.container ul{
    list-style-type: none;
}

.container ul li{
    margin: 2px;
}

.container_agent_conv{
    background-color: #FFFFFF;
    padding: 34px 19px;
    margin: 14px 19px;
    border-radius: 23px;
    width: 50%;
    height: 15%;
    margin-left: 20%;
    margin-top: 5%;
}

.container_agent_conv_main{
    background-color: #FFFFFF;
    padding: 34px 19px;
    margin: 14px 19px;
    border-radius: 23px;
    width: 50%;
    height: 40%;
    margin-left: 20%;
    overflow: scroll;
}

.container_agent_conv ul{
    list-style-type: none;
}

.container_agent_conv ul li{
    margin: 2px;
    font-family: 'Inter';
}


.agent_b {
    width: 100%;
    height: 45px;
    background-color: #0099ff;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-top: 40%;
    margin-bottom: 0;
}


.error_message
{
    color: red;
    text-align: center;
    margin-bottom: 2%;
}

h4{
    font-family: 'Roboto';
    font-size: 28px;
    text-align: left;
    margin-left: 5%;
 }

.content h2 {
    font-family: 'Roboto';
    margin-top: 3%;
    font-size: 30px;
    margin-left: 3%;
}

.container {
    font-family: 'Roboto';
}

img {
    width: 40px;
    height: 40px;
    margin-left: 1%;
    border-radius: 50%;
  }

  a:hover {
    text-decoration:underline;
    }

.logout_b{
    width: auto;
    height: auto;
    margin-left: auto;
    margin-bottom: 10%;
    background-color: lightgray;
    display: inline-block;
    object-fit: cover; 
    position: fixed;
    bottom: 3%;
}

.logout-nav{
    position: fixed;
    bottom: 3%;
    width: 20%;
    width: 250px;
    height: 70px;
    background: lightgray;
    border-radius: 40px;
    display: inline-block;
    object-fit: cover;
}

.agent_description{
    color: #7D7D7D;
}


.container_agent_conv_end{
    /* background-color: #FFFFFF; */
    padding: 34px 19px;
    border-radius: 23px;
    width: auto;
    margin-left: 30%;
}


/* button{
    width: 100%;
    height: 45px;
    background-color: #0099ff;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-top: 40%;
    margin-bottom: 0;
} */

.container_agent_conv_end button{
    text-align: center;
    background-color: #FF0000;
    font-family: 'Inter';
    font-size: 19px;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    color: white;
    width: 40%;
    height: 45px;
}