
.sidebar{
    background-color: #FFFFFF;
}

.logout-nav{
    position: fixed;
    bottom: 3%;
}

.content {
    background-color: #F9F9F9;
}

.container {
    background-color: #FFFFFF;
    border: 3px solid #0099ff;

    padding: 34px 19px;
    margin: 14px 19px;
    border-radius: 23px;
    width: 25%;
    height: 25%;
}

.container ul{
    list-style-type: none;
}

.container ul li{
    margin: 2px;
}



.error_message
{
    color: red;
    text-align: center;
    margin-bottom: 2%;
}

h4{
    font-family: 'Roboto';
    font-size: 28px;
    text-align: left;
    margin-left: 5%;
 }


 h2 {
    font-family: 'Roboto';
}

.container {
    font-family: 'Roboto';
}