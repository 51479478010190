/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

header {
  background-color: #007bff;
  color: #fff;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Chat Window Styles */
.chat-window {
  width: 500px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.chat-area {
  height: 400px;
  overflow-y: scroll;
  padding: 20px;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
}

.user-input {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

#message-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#send-button {
  padding: 8px 16px;
  margin-left: 10px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

#send-button:hover {
  background-color: #0056b3;
}