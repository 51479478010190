/* .wrapper {
    position: relative;
    width: 50%;
    float: left;

} */

.wrapper{
    margin: 0;
    padding: 12px 20px;
    width: 20%;
    background-color: #f1f1f1;
    position: fixed;
    height: 90%;
    overflow: auto;
}

.wrapper .user-guide {
    width: 100%;
    padding: 40px;
}


div.wrapper2 {
    margin-left: 55%;
    background-color: #f2f2f2;
    padding: 0px 16px;
    height: auto;
    position: fixed;
  }

/* .wrapper2 .form-box {
    width: 100%;
    padding: 40px;
} */

form h1{
    font-size: 36px;
    text-align: center;
    font-family: 'Poppins';
}

form .input-box{
    position: relative;
    width: 60%;
    height: 50px;
    margin: 30px 0;
    margin-left: 20%;
    text-align: center;
}

.input-box input{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* .input-box input{
    width: 100%;
    height: 100%;
    border: 2px solid (255, 255, 255, .1);
    border-radius: 40px;
} */


#content { 
    overflow:auto; 
    width: 100%;
    height: 100%; 
    background: gray; 
    position: relative;
  } 
  
  #left { 
    width: 55%;
    background: white; 
    float: left;
    height: 100%;
    padding: 3px 4px;
  } 
  

  #right { 
    width: 45%;
    /* background: lightgray; */
    float: right;
    height: 100%;
    padding: 3px 1px;
  }

  .wrapper2_top img {
    width: 447px;
    height: 325px;
    margin-left: 15%;
  }

  .accept-terms{
    color: black;
    margin-top: 5%;
    /* height: 50px; */
    text-align: center;
    font-family: 'Poppins';
  }

   .accept-terms input{
    margin: 0 15px 0 0px;
    font-size: 16px;

   }

   
  /* form button{

    width: 50%;
    height: 45px;
    background-color: #0099ff;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: 700;
    margin: auto;


} */

.btn{
    width: 50%;
    height: 45px;
    background-color: #0099ff;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    font-weight: 700;
    margin: auto;
    padding: 14px 20px;
    box-sizing: border-box;
}

.btn2{
    width: 8%;
    height: 20px;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: cornflowerblue;
    /* font-weight: 700; */
    margin: auto;
    margin-top: 10%;
    font: bold;
}

a {
    cursor: pointer;
}

.input_title{
    text-align: left;
    font-family: 'Poppins';
}



.submit-container{
    text-align: center;
    margin-top: 3%;
}

.form-box-login{
    margin-top: 12%;
}

.terms_link{
    text-decoration: underline;
    cursor: pointer;
}


.wrapper2_top{
    margin-top: 0;
    height: 50%;
    background-color: #F7F8FB;
}

.wrapper2_buttom{
    height: 50%;
    margin-bottom: 0;
    background-color: #F7F8FB;
}

.wrapper2_content{
    margin-top: 0%;
    margin-left: 30%;
    color: grey;
}

li.menu-item:not(:last-child) { 
    margin-bottom: 11px; 
    font-family: 'Poppins'; 
 }



 .wrapper2_content h2{

    margin-bottom: 15px;
    font-family: 'Poppins';

 }